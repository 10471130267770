<template>
  <div  class="body">
     <div>
       <div style="width: 100vw;height: 8px;background: #F5F5F5;"></div>
       <div v-if="sysName==='saas' " style="background: #FFFFFF;border-top: 1px solid #EEEEEE;padding-bottom: 16px;">
         <div style="margin: 16px 16px 15px 16px;display: flex;align-items: center;">
           <div>已绑定户号</div>
           <div style="margin-left:auto;display: flex;align-items: center;" @click="bindManager">
             <div v-show="userList.length > 0" style="color:#0069FF;">绑定管理</div>
             <van-icon v-show="userList.length > 0" name="arrow" color="#1989fa"/>
           </div>

         </div>
         <div v-if="userList.length == 0" @click="bindWaterCode" style="border-radius: 4px;border: 1px dashed #0069FF;height: 62px;margin: 0 16px;display: flex;justify-content: center;align-items: center;">
           <van-icon name="plus" color="#1989fa"/>
           <div style="color: #333333;margin-left: 4px;">绑定户号</div>
         </div>
         <div v-if="!isFold && userList.length" v-for="item in [userList[0]]" style="margin: 0 16px 16px 16px;background: #F2F7FF;border-radius: 4px;padding: 12px;display: flex;align-items: center;">
           <div>
             <div style="color: #333333;">户名：{{item.userName}}</div>
             <div style="color: #333333;margin-top: 4px;">户号：{{item.waterCode}}</div>
           </div>
           <div @click="choose(item)" style="margin-left: auto;text-align: center;">
             <van-icon name="gold-coin-o" color="#6B83A6" size="24"/>
             <div style="font-size: 12px;color: #666666;">去缴费</div>
           </div>
           <div @click="jumpPayment(item)" style="text-align: center;margin-left: 20px;">
             <van-icon name="orders-o" color="#6B83A6" size="24"/>
             <div style="font-size: 12px;color: #666666;">历史水费</div>
           </div>
         </div>
         <div v-else v-for="item in userList" style="margin: 0 16px 16px 16px;background: #F2F7FF;border-radius: 4px;padding: 12px;display: flex;align-items: center;">
           <div>
             <div style="color: #333333;">户名：{{item.userName}}</div>
             <div style="color: #333333;margin-top: 4px;">户号：{{item.waterCode}}</div>
           </div>
           <div @click="choose(item)" style="margin-left: auto;text-align: center;">
             <van-icon name="gold-coin-o" color="#6B83A6" size="24"/>
             <div style="font-size: 12px;color: #666666;">去缴费</div>
           </div>
           <div @click="jumpPayment(item)" style="text-align: center;margin-left: 20px;">
             <van-icon name="orders-o" color="#6B83A6" size="24"/>
             <div style="font-size: 12px;color: #666666;">历史水费</div>
           </div>
         </div>
         <div v-if="userList.length" class="fold" @click="isFold=!isFold">
            {{isFold?'折叠全部':'显示全部'}}
           <van-icon name="arrow-down" color="#1989fa" size="14"/>
         </div>
       </div>
       <van-form @submit="onSubmit">
		   <van-notice-bar
           v-if="sysName!='saas' "
		       left-icon="volume-o"
		       text="注: 晚上23:00-凌晨2:00点为系统对账时间，暂不支持缴费，谢谢!"
		   />
         <!-- 允许输入正整数，调起纯数字键盘 -->
         <van-search
             v-model="waterCode"
             shape="round"
             show-action
             :type="sysName =='zs'?'text':'digit'"
             @search="onSearch"
             placeholder="请输入缴费户号"
         >
           <template #action>
             <div @click="onSearch" style="color: #0069FF;">搜索</div>
           </template>
         </van-search>
		 <div style="width: 100vw;height: 8px;background: #F5F5F5;"></div>
         <van-field
             v-model="from.waterCode"
             label="缴费户号"
             placeholder="缴费户号"
             readonly
         />
         <van-field
             v-model="from.customerName"
             label="缴费户名"
             placeholder="缴费户名"
             readonly
         />
         <van-field
             v-model="from.customerAddr"
             label="用户地址"
             placeholder="用户地址"
             readonly
         />
         <van-field
             v-model="from.chargingUnit"
             label="收费单位"
             placeholder="收费单位"
             readonly
         />
         <van-field
             v-model="from.payAmount"
             label="应缴金额"
             placeholder="应缴金额"
             readonly
         />
         <van-field
             v-model="from.balance"
             label="结余金额"
             placeholder="结余金额"
             readonly
         />
		 <div style="width: 100vw;height: 8px;background: #F5F5F5;"></div>
         <van-field
             v-model="payAmount"
             clearable
             label="￥"
             placeholder="请输入缴费金额"
             label-width="30px"
             size="large"
             style="display: flex;align-items: center;"
			 type="number"
         >
			<template #button>
			    <van-button :disabled="payAmount == '' || payCheck" :loading="payCheck" :loading-text="payCheck ? '支付中' : ''" type="primary" size="small" style="background: #0069FF;border: 0px solid #0069FF;border-radius: 4px;width: 96px;height: 36px;">立即缴费</van-button>
			  </template>
		 </van-field>
			<div>
				<van-row gutter="10" type="flex" justify="center" style="height: 8vh;background-color: #FFFFFF;margin: 0 5vw;">
				  <van-col span="6"><div class="candidate" :style="chooseCheck1 ? 'color: rgb(0, 105, 255); border: 1px solid rgb(0, 105, 255); background-color: #FFFFFF;' : ''" @click="chooseMoney(1)">50元</div></van-col>
				  <van-col span="6"><div class="candidate" :style="chooseCheck2 ? 'color: rgb(0, 105, 255); border: 1px solid rgb(0, 105, 255); background-color: #FFFFFF;' : ''" @click="chooseMoney(2)">100元</div></van-col>
				  <van-col span="6"><div class="candidate" :style="chooseCheck3 ? 'color: rgb(0, 105, 255); border: 1px solid rgb(0, 105, 255); background-color: #FFFFFF;' : ''" @click="chooseMoney(3)">200元</div></van-col>
				  <van-col span="6"><div class="candidate" :style="chooseCheck4 ? 'color: rgb(0, 105, 255); border: 1px solid rgb(0, 105, 255); background-color: #FFFFFF;' : ''" @click="chooseMoney(4)">500元</div></van-col>
				</van-row>
			</div>
       </van-form>
      <!--<van-popup v-model="show">内容</van-popup>-->
     </div>
	 <div style="width: 100vw;height: 8px;background: #F5F5F5;"></div>
		<div v-if="sysName !='saas' " style="background: #FFFFFF;border-top: 1px solid #EEEEEE;padding-bottom: 16px;">
			<div style="margin: 16px 16px 15px 16px;display: flex;align-items: center;">
				<div>已绑定户号</div>
				<div style="margin-left:auto;display: flex;align-items: center;" @click="bindManager">
					<div v-show="userList.length > 0" style="color:#0069FF;">绑定管理</div>
					<van-icon v-show="userList.length > 0" name="arrow" color="#1989fa"/>
				</div>

			</div>
			<div v-if="userList.length == 0" @click="bindWaterCode" style="border-radius: 4px;border: 1px dashed #0069FF;height: 62px;margin: 0 16px;display: flex;justify-content: center;align-items: center;">
				<van-icon name="plus" color="#1989fa"/>
				<div style="color: #333333;margin-left: 4px;">绑定户号</div>
			</div>
			<div v-for="item in userList" style="margin: 0 16px 16px 16px;background: #F2F7FF;border-radius: 4px;padding: 12px;display: flex;align-items: center;">
				<div>
					<div style="color: #333333;">户名：{{item.userName}}</div>
					<div style="color: #333333;margin-top: 4px;">户号：{{item.waterCode}}</div>
				</div>
				<div @click="choose(item)" style="margin-left: auto;text-align: center;">
					<van-icon name="gold-coin-o" color="#6B83A6" size="24"/>
					<div style="font-size: 12px;color: #666666;">去缴费</div>
				</div>
				<div @click="jumpPayment(item)" style="text-align: center;margin-left: 20px;">
					<van-icon name="orders-o" color="#6B83A6" size="24"/>
					<div style="font-size: 12px;color: #666666;">历史水费</div>
				</div>
			</div>
		</div>

	<!-- <van-row v-for="item in userList" type="flex" justify="center" align="center" style="margin: 5px;">
	  <van-col span="7">户号: {{item.waterCode}}</van-col>
	  <van-col span="7">户名: {{item.userName}}</van-col>
	  <van-col span="5"><van-button round type="primary" size="small" @click="choose(item)">去缴费</van-button></van-col>
	  <van-col span="5"><van-button round type="primary" size="small" @click="jumpPayment(item)">历史缴费记录</van-button></van-col>
	</van-row> -->

  </div>
</template>

<script>
import payhead from '../components/payhead'
import request from "@/api/index.js";
import {appId,SystemName} from '@/settings.js'
// 判断是否为微信环境
	const isWechat = () => {
		return String(navigator.userAgent.toLowerCase().match(/MicroMessenger/i)) === "micromessenger";
	}
export default {
  name: '',
  components: {
    payhead
  },
  props: {},
  data() {
    return {
      sysName:SystemName,
      isFold:false,
      show:false,
      code:'',
      payAmount:'',
      waterCode:'',
      orgId:'',
      tenantCode:'',
      appId:appId,
      from:{
        userName:'',
        waterCode:'',
      },
      token:'',
	  userList: [],
	  payCheck: false,
	  chooseCheck1: false,
	  chooseCheck2: false,
	  chooseCheck3: false,
	  chooseCheck4: false
    }
  },
  computed: {

  },
  watch: {
  },
  created() {
	  let params = this.$route.query;
	  if(params.waterCode != undefined && params.waterCode != ''){
		  this.waterCode = params.waterCode
	  }
    if (this.sysName =='saas'){
      let tenantCode = params.tenantCode
      if (tenantCode || tenantCode == 0){
        this.tenantCode =tenantCode
        localStorage.setItem("tenantCode",this.tenantCode)
      }
      let appSaasId = params.appId
      if (appSaasId){
        this.appSaasId =appSaasId
        localStorage.setItem("appSaasId",this.appSaasId)
      }
      let orgId = params.orgId;
      if (orgId || orgId == 0){
        this.orgId = orgId
        localStorage.setItem("orgId",this.orgId)
      }
    }
	  this.getWxCode();
	  if(this.code != ''){
	  	this.getToken();
	  }
     // this.code=this.getUrlCode().code
     // this.getToken()
  },
  mounted() {

  },
  methods: {
		bindManager(){
      request.get('/wechat/publicAccount/checkWechatOpenIdBindWaterCodeAndUserName',{ params: {code:this.code,token: this.token ? this.token : localStorage.getItem("orderToken")} }).then(res=>{
			if(res.code == 200){
				this.$router.push({path: '/zsBindWaterCodeSuccess', query: {openId: res.data}})
			}
			}).catch(err=>{
				this.$toast.fail(err.message)
			})
		},
		bindWaterCode(){
			this.$router.push({path: '/zsBindWaterCodeTemplate'})
		},
		queryBindUserListByOpenId(){
			let data = {
			  token: this.token ? this.token : localStorage.getItem("orderToken")
			}
			request.post('/wechat/publicAccount/queryBindUserListByToken', data).then(res=>{
				if(res.code == 200){
					this.userList = res.data
				}else{
					this.$toast.fail(res.msg)
				}
			})
		},
		choose(item){
			this.waterCode = item.waterCode
			this.onSearch()
		},
    getToken(){
      let data
      if (this.sysName === 'saas'){
        data ={code:this.code,tenantCode:this.tenantCode?this.tenantCode:localStorage.getItem("tenantCode"),orgId:this.orgId?this.orgId:localStorage.getItem("orgId")}
      }else {
        data = {code:this.code}
      }
      request.get('/pay/waterFee/token',{params:data}).then(res=>{
        this.token=res.data
        localStorage.setItem("orderToken",this.token)
        if (this.sysName === 'saas' && this.waterCode){
          this.onSearch()
        }
		  this.queryBindUserListByOpenId()
      }).catch(err=>{
        alert(err.message)
      })
    },
    onSearch(value){
      if (!this.waterCode){
        this.$dialog({ message: '请输入正确的户号！' });
        return
      }
      request.get('/pay/waterFee/query',{params:{waterCode:this.waterCode,token:localStorage.getItem("orderToken")}}).then(res=>{
        this.from=res.data
        this.from.payAmount=Number(this.from.payAmount)/100
        this.from.balance=Number(this.from.balance)/100
		if(this.userList.length > 0){
			var check = this.userList.some(item => item.waterCode == this.from.waterCode)
			 if(!check){
				 this.from.customerName = this.from.customerName.slice(0,1) + '**'
				 this.from.customerAddr = '***' + this.from.customerAddr.slice(3)
			 }
		}else{
			this.from.customerName = this.from.customerName.slice(0,1) + '**'
			this.from.customerAddr = '***' + this.from.customerAddr.slice(3)
		}
      }).catch(err=>{
        this.$dialog({ message: '水表信息有误！' });
      })
      /*request.get('/article/article/list', { params: {categoryId: 12} }).then(res => {
        console.log(res)
      })*/
    },
    onSubmit(values){
      if (!this.from.waterCode){
        this.$dialog({ message: '请输入正确的户号！' });
        return
      }
      if (Number(this.payAmount)<Number(this.from.payAmount)){
        this.$dialog({ message: '缴费金额不能小于应缴金额！' });
        return
      }
      let data={
        payAmount:this.payAmount,
        waterCode:this.from.waterCode,
        token:this.token?this.token:localStorage.getItem("orderToken"),
      }
	    this.payCheck = true
      request.post('/pay/waterFee/order',data).then(res=>{
        if (res.code=='200' || res.code==200){
          let da=eval("("+res.data.payData+")")
          WeixinJSBridge
              .invoke(
                  'getBrandWCPayRequest',
                  {
                    "appId": da.appId, //公众号名称，由商户传入
                    "timeStamp": da.timeStamp, //时间戳，自1970年以来的秒数
                    "nonceStr": da.nonceStr, //随机串
                    "package": da.package,
                    "signType": da.signType, //微信签名方式:
                    "paySign": da.paySign
                    //微信签名
                  },
                  function (res) {
                    if (res.err_msg == "get_brand_wcpay_request:ok") {
                      alert("支付成功");
					            this.payCheck = false
                    }
                    if (res.err_msg == "get_brand_wcpay_request:cancel") {
                      alert("交易取消");
                      this.payCheck = false
                    }
                    if (res.err_msg == "get_brand_wcpay_request:fail") {
                      alert("支付失败");
					            this.payCheck = false
                    }
					            this.payCheck = false
                  });
                  this.payCheck = false
                  this.from = {
                    userName:'',
                    waterCode:''
                  }
                  this.payAmount = ''
                }else {
                  alert("支付错误")
                  this.payCheck = false
                }
              }).catch(err=>{
                  alert(err.message)
                  this.payCheck = false
                })
              this.show = true;
      /*if (this.payAmount<this.from.payAmount){
        this.$dialog({ message: '缴费金额不能小于应缴金额！' });
      }else {
        orderRequest.post('',{})
      }*/
    },
	getWxCode() {
		if (isWechat) {
			let code = this.getUrlCode().code; //是否存在code
      if (this.sysName =='saas'){
        let tenantCode = this.getUrlCode().tenantCode
        if (tenantCode || tenantCode == 0){
          this.tenantCode =tenantCode
          localStorage.setItem("tenantCode",this.tenantCode)
        }
        let appSaasId = this.getUrlCode().appId
        if (appSaasId){
          this.appSaasId =appSaasId
          localStorage.setItem("appSaasId",this.appSaasId)
        }
        let orgId = this.getUrlCode().orgId;
        if (orgId || orgId == 0){
          this.orgId = orgId
          localStorage.setItem("orgId",this.orgId)
        }
        let waterCode = this.getUrlCode().waterCode
        if(waterCode){
          this.waterCode = waterCode
        }
      }
			let local = window.location.href;
      //local =  local.replace('http://lp.saas.jincaiyun.cn:9999','https://lp.test.jcrjsw.com')
			if (code == null || code === "") {
        let appIds = this.sysName==='saas' ? (this.appSaasId ? this.appSaasId : localStorage.getItem("appSaasId")) : this.appId
			  //不存在就打开上面的地址进行授权
			  window.location.href =
				"https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
            appIds +
				"&redirect_uri=" +
				encodeURIComponent(local) +
				"&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
			} else {
				this.code = code;
			}
      //this.code = code;
		}
	},
    getUrlCode() {
      // 截取url中的code方法
      var url = location.search;
      var theRequest = new Object();
      if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      return theRequest;
    },
    jumpPayment(item){
      this.$router.push({path: '/historywater', query: {waterCode: item.waterCode, userName: item.userName,token:this.token?this.token:localStorage.getItem("orderToken")}})
    },
	chooseMoney(val){
		if(val == 1){
			this.chooseCheck1 = true
			this.chooseCheck2 = false
			this.chooseCheck3 = false
			this.chooseCheck4 = false
			this.payAmount = '50.00';
		}else if(val == 2){
			this.chooseCheck1 = false
			this.chooseCheck2 = true
			this.chooseCheck3 = false
			this.chooseCheck4 = false
			this.payAmount = '100.00';
		}else if(val == 3){
			this.chooseCheck1 = false
			this.chooseCheck2 = false
			this.chooseCheck3 = true
			this.chooseCheck4 = false
			this.payAmount = '200.00';
		}else if(val == 4){
			this.chooseCheck1 = false
			this.chooseCheck2 = false
			this.chooseCheck3 = false
			this.chooseCheck4 = true
			this.payAmount = '500.00';
		}
	},
  },
}
</script>
<style lang='scss' scoped>
.body{
	height: 100vh;
	background: #FFFFFF;
}
.fold {
  padding: 24rpx 0;
  font-weight: 400;
  font-size: 28rpx;
  color: #006BFE;
  line-height: 44rpx;
  text-align: center;
}
.candidate {
  height: 100%;
  background: #EAF6FF;
  color: #333;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
